<template>
  <div class="cell">
    <div class="cell-left">{{ label }}: </div>
    <div class="cell-right">
      <slot></slot>{{ value }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CellValue',
  props: {
    label: String,
    value: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cell {
  display: flex;
  padding: 10px 15px;
}

.cell-left {
  color: #333;
  margin-right: 5px;
  flex: none;
}

.cell-right {
  color: #999;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import Order from '../views/order'

const routes = [
  {
    path: '/order',
    name: Order,
    component: Order,
    meta: { title: '订单审核' }
  
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

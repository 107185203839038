// 回显数据字典
export function selectDictLabel(datas, value) {
    var actions = []
    if (datas) {
        Object.keys(datas).some((key) => {
            if (datas[key].dictValue.toString() === ('' + value)) {
                actions.push(datas[key].dictLabel)
                return true
            }
        })
    }
    return actions.join('')
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
    var actions = []
    var currentSeparator = undefined === separator ? ',' : separator
    var temp = value.split(currentSeparator)
    Object.keys(value.split(currentSeparator)).some((val) => {
        Object.keys(datas).some((key) => {
            if (datas[key].dictValue === ('' + temp[val])) {
                actions.push(datas[key].dictLabel + currentSeparator)
            }
        })
    })
    return actions.join('').substring(0, actions.join('').length - 1)
}
import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import Vant from 'vant';
import 'ant-design-vue/dist/reset.css';
import 'vant/lib/index.css'
import App from './App.vue';
import router from './router'
import { getDicts } from '@/api/dict'
import { selectDictLabel, selectDictLabels } from '@/utils/bes'

const app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.getDicts = getDicts
app.config.globalProperties.selectDictLabel = selectDictLabel
app.config.globalProperties.selectDictLabels = selectDictLabels
app.use(router) //注册路由
app.use(Vant);
app.use(Antd).mount('#app');
router.beforeEach((to, from, next) => {
    // 设置页面标题
    document.title = to.meta.title || '默认标题'
    next()
})